div[aria-expanded="true"] .plusIcon {
    display: inline-block !important;
  }
  div[aria-expanded="false"] .minusIcon {
    display: inline-block !important;
  }
  .fs18{
    font-size: 18px;
  }
  .fw900{
    font-weight: 900;
  }
  .colorGrey{
    color: gray;
  }
  .fs16{
    font-size: 16px;
  }
  .iconFs {
    font-weight: 900;
    font-size: 34px;
  }
  .iconFs img {
    width: 20px;
  }
  .ml10{
    margin-left: 10px;
  }
  .gj_top_header a:hover {
    color: #fff;
  }