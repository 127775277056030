.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skh span {
  transform: scale(1, 1) !important;
  height: 10vh !important;
}
.sk span {
  transform: scale(1, 1) !important;
  height: 100vh;
}
.fw700{
  font-weight: 700;
}
.subtitle,.subtitle a,.colorPara{
  color: #555;
}
.starIc {
  width: 15px;
}
.fs18{
  font-size: 18px;
}
.titleUnderline{
  font-weight: 700;
  color: rgba(0,0,0,.9);
  font-size: 30px;
  position: relative;
}
.titleUnderline::after {
  position: absolute;
  width: 100%;
  background: #005ca9;
  bottom: 3px;
  height: 2px;
  content: "";
  left: 0px;
  z-index: -1;
}
.Karla{
  font-family: Karla;
}
.card_button {
  bottom: auto;
  font-size: 15px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all .15s ease;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid rgba(0,0,0,.9);
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
  background-color: transparent;
  color: #000;
  padding: 8px 30px;
  text-transform: uppercase;
}
.bgLightGreen{
  background: #f1fbf5;
}
.rounded12{
  -webkit-border-radius: 12px;
  border-radius: 12px;
}
.fs15{
  font-size: 15px;
}
.fs14{
  font-size: 14px;
}
.fs13{
  font-size: 13px;
}
.fontGreatVibes{
  font-family: 'Great Vibes', cursive !important;
}
@font-face {
  font-family: OPTICenturyNova;
  src: url(./Assets/font/OPTICenturyNova.otf);
}
.fontOptiCentury{
  font-family: OPTICenturyNova !important;
}
.fs30{
  font-size: 30px;
}
.fw500{
  font-weight: 500;
}
